import {
  Typography,
  Switch as MuiSwitch,
  withStyles,
  Box,
} from "@material-ui/core";
import React from "react";
import cx from "classnames";

import {
  switchThumbColorPrimary,
  switchTrackColorGrey,
  useCommonStyles,
} from "../../../../utils/loan-application.styles";

const StyledSwitch = withStyles({
  root: {
    width: 43,
    height: 20,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: switchThumbColorPrimary,
    "&$checked": {
      transform: "translateX(22px)",
      color: switchThumbColorPrimary,
      "& + $track": {
        opacity: 1,
        backgroundColor: switchTrackColorGrey,
        borderColor: switchTrackColorGrey,
      },
    },
  },
  thumb: {
    width: 18,
    height: 18,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${switchTrackColorGrey}`,
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: switchTrackColorGrey,
  },
  checked: {},
})(MuiSwitch);

const Switch = ({
  showValue = false,
  valueLabels = ["No", "Yes"],
  label,
  value,
  onChange,
}) => {
  const commonClasses = useCommonStyles();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      gridColumnGap="16px"
      alignItems="center"
    >
      <Typography
        classes={{ root: cx(commonClasses.fontSize14, commonClasses.textGrey) }}
      >
        {label}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        gridColumnGap="8px"
        alignItems="center"
      >
        {showValue && (
          <Typography classes={{ root: commonClasses.fontSize14 }}>
            {valueLabels[0]}
          </Typography>
        )}
        <StyledSwitch
          checked={value}
          onChange={(event, checked) => onChange(checked)}
        />
        {showValue && (
          <Typography classes={{ root: commonClasses.fontSize14 }}>
            {valueLabels[1]}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Switch;
